import { setupWorker } from "msw/browser"
import React from "react"
import { hydrateRoot } from "react-dom/client"

import { appClientMock } from "@aviv-ipp/app"

import { PageShell } from "./PageShell"
import type { PageContextClient } from "./types"

// This render() hook only supports SSR, see https://vike.com/render-modes for how to modify render() to support SPA
export async function render(pageContext: PageContextClient) {
  const { Page, environment } = pageContext

  const root = document.getElementById("react-root")
  if (!root) throw new Error("DOM element #react-root not found")

  if (environment === "local") {
    const worker = setupWorker(...appClientMock)
    await worker.start({ onUnhandledRequest: "bypass" })
  }

  hydrateRoot(
    root,
    <PageShell pageContext={pageContext}>
      <Page pageContext={pageContext} />
    </PageShell>
  )
}
